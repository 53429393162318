import { FC, useMemo, useState } from 'react';
import { ClientFormUserRole, ClientFormUserRoleKeys } from '../../models/ClientFormUserRoles';
import User from '../../models/User';
import CheckIcon from '../shared/icon/CheckIcon';
import ProfileImage, { ImageSize } from '../shared/profile-image/ProfileImage';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { useTranslation } from 'react-i18next';
import { ClientFormUserOnly } from '../../models/ClientFormUser';
import { Option } from '../Option';
import { SelectListMenu } from '../shared/SelectListMenu';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';

type ProfileImageStackProps = {
  users?: ClientFormUserOnly[];
  size?: ImageSize;
  caption?: string;
  displayLimit?: number;
};

const ProfileImageStack: FC<ProfileImageStackProps> = (props) => {
  const { users = [], size = ImageSize.S, caption, displayLimit = 3 } = props;
  const currentUser = useRecoilValue(currentUserAtom);
  const { t } = useTranslation(['common']);
  const usersToDisplay = users.slice(0, displayLimit);
  const remainingUsers = users.slice(displayLimit);

  const [showMore, setShowMore] = useState(false);

  let moreSizeCss = 'w-12 h-12 text-dpm-14';

  switch (size) {
    case ImageSize.XS:
      moreSizeCss = 'min-w-8 w-8 h-8 text-dpm-12';
      break;
    case ImageSize.S:
      moreSizeCss = 'min-w-10 w-10 h-10 text-dpm-12';
      break;
    default:
      moreSizeCss = 'min-w-12 w-12 h-12 text-dpm-14';
      break;
  }

  const RemainingUsersListRenderer = useMemo(() => {
    return function ListRenderer(props: Option<string, string | number>) {
      const userIndex = remainingUsers.findIndex((x) => x.id === props.id);
      const user = remainingUsers[userIndex] as ClientFormUserOnly;
      return (
        <div key={user.id} className={`flex p-1 ${userIndex === remainingUsers.length - 1 ? 'border-b-0' : 'border-b'} border-gray-6`}>
          <div className="px-1">
            <ProfileImage data-cy={`user-${user.id}`} user={user as User} size={size} />
          </div>
          <div className="px-1">
            <span className="block">
              {user.firstName} {user.lastName}
            </span>
            <span className="text-dpm-12">{user.role ? (t(ClientFormUserRoleKeys[user.role]) as string) : ''}</span>
          </div>
        </div>
      );
    };
  }, [remainingUsers, size, t]);

  return (
    <div className="flex h-full" data-cy="profile-stack">
      <div className="flex flex-col self-center">
        <span className={`text-dpm-12 text-center font-medium ${caption ? 'mb-3' : ''}`} data-cy="caption" aria-label={t('aria-label.caption')}>
          {caption}
        </span>
        <div className="profile-image-stack relative flex justify-items-center self-center">
          {users.length === 0 && <ProfileImage data-cy="no-users" data-user-count={0} size={size} />}
          {users.length !== 0 &&
            usersToDisplay
              .sort((x, y) => {
                return x.id === currentUser?.id ? -1 : y.id === currentUser?.id ? 1 : 0;
              })
              .map((user, index) => {
                return (
                  <div key={index} className="relative">
                    {user.acceptedStatus &&
                      user.statusUtc &&
                      (user.role === ClientFormUserRole.Validator || user.role === ClientFormUserRole.Approver) && (
                        <div
                          data-cy="validated-icon"
                          className="bg-gray-4 absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full border-2 border-white text-white"
                        >
                          <CheckIcon className="h-3 w-3" />
                        </div>
                      )}
                    <ProfileImage
                      data-cy={`user-${user.id}`}
                      data-user-count={users.length}
                      user={user as User}
                      role={user.role ? t(ClientFormUserRoleKeys[user.role]) : ''}
                      size={size}
                      withMargin={index > 0 && users.length > 1}
                    />
                  </div>
                );
              })}
          {remainingUsers.length > 0 && (
            <>
              <SelectListMenu
                isOpen={showMore}
                options={remainingUsers.map((user) => {
                  return {
                    id: user.id || '',
                    value: `${user.firstName} ${user.lastName}`,
                    text: `${user.firstName} ${user.lastName}`,
                  };
                })}
                customListItemRenderer={RemainingUsersListRenderer}
                onBlur={() => setShowMore(false)}
                className="border-none text-left"
                width="w-fit"
              >
                {(triggerProps) => (
                  <div {...triggerProps} className="relative">
                    <div
                      {...mouseAndKeyboardCallbackProps((e) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        setShowMore(!showMore);
                      })}
                      className={`bg-gray-5 relative -ml-2 -mt-[1px] inline-flex items-center justify-center rounded-full border-2 border-white p-1 pt-[5px] font-medium text-black ${moreSizeCss} cursor-pointer`}
                    >
                      +{remainingUsers.length}
                    </div>
                  </div>
                )}
              </SelectListMenu>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { ImageSize, ProfileImageStack };
